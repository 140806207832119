.example-gallery {
  .image-gallery-slide-wrapper {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

.image-gallery-bullets-container {
  align-items: center;
  display: flex;
  gap: 8px;
}

.image-gallery-bullet {
  padding: 0;
  border: none;
  background-color: transparent;

  &::before {
    content: '';

    width: 10px;
    height: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 6px;

    background-color: #ddd;

    transition: all 300ms ease;

    cursor: pointer;
  }

  &:hover::before {
    background-color: #cbad73;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover::before {
      background-color: #cbad73;
    }
  }
}

.active {
  &::before {
    width: 24px;
    background-color: #cbad73;
  }
}
