/* Файл используется для изменения стилей библиотеки под текущий макет */

.tabsUpperContainerStyles {
  padding: 0;
  margin: 0;
}

.tabsContainerStyles {
  padding: 0;
  margin: 0;
}

.rts___nav___btn___container {
  position: relative;
}

.navBtnStyles {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
}

/* библиотека на мобильных разрешениях уменьшает размер стрелок  */
.navBtnStyles > svg {
  width: 24px;
  height: 24px;
}

.navBtnStyles:hover {
  background-color: transparent;
}

/* стиль "стрелок" согласно макета и их расположение  */
.leftNavBtnClassStyles {
  position: absolute;
  top: 8px;
  right: -24px;

  border: none;
  border-radius: 0;

  background: linear-gradient(90deg, #FFF 52.5%, rgba(255, 255, 255, 0.00) 100%);

  opacity: 1;

  transition-property:all;
  transition-timing-function: linear;
  transition-duration: 0.5s;
}

.rightNavBtnClassStyles {
  position: absolute;
  top: 8px;
  left: -24px;

  border: none;
  border-radius: 0;

  background: linear-gradient(-90deg, #FFF 52.5%, rgba(255, 255, 255, 0.00) 100%);

  opacity: 1;

  transition-property:all;
  transition-timing-function: linear;
  transition-duration: 0.5s;
}

.navBtnOff {
  opacity: 0;
}

.tabsContainerStyles {
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: transparent;

  box-shadow: none;
  border: none;
  border-radius: 0;
}

