/* roboto-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-100.eot"); /* IE9 Compat Modes */
  src:
    url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-100.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-100.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-100.woff") format("woff"),
    /* Modern Browsers */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-100.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-100.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-regular.eot"); /* IE9 Compat Modes */
  src:
    url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-500.eot"); /* IE9 Compat Modes */
  src:
    url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-500.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-700.eot"); /* IE9 Compat Modes */
  src:
    url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../scss/fonts/roboto-v30-latin/roboto-v30-latin-700.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../scss/fonts/open-sans-v35-latin/open-sans-v35-latin-regular.eot"); /* IE9 Compat Modes */
  src:
    url("../scss/fonts/open-sans-v35-latin/open-sans-v35-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../scss/fonts/open-sans-v35-latin/open-sans-v35-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../scss/fonts/open-sans-v35-latin/open-sans-v35-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../scss/fonts/open-sans-v35-latin/open-sans-v35-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../scss/fonts/open-sans-v35-latin/open-sans-v35-latin-regular.svg#OpenSans")
      format("svg"); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../scss/fonts/open-sans-v35-latin/open-sans-v35-latin-700.eot"); /* IE9 Compat Modes */
  src:
    url("../scss/fonts/open-sans-v35-latin/open-sans-v35-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../scss/fonts/open-sans-v35-latin/open-sans-v35-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../scss/fonts/open-sans-v35-latin/open-sans-v35-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../scss/fonts/open-sans-v35-latin/open-sans-v35-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../scss/fonts/open-sans-v35-latin/open-sans-v35-latin-700.svg#OpenSans")
      format("svg"); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
  url("../scss/fonts/open-sans-v35-latin/open-sans-v40-latin-600.woff2") format("woff2"), /* Super Modern Browsers */
  url("../scss/fonts/open-sans-v35-latin/open-sans-v40-latin-600.woff") format("woff"), /* Pretty Modern Browsers */
  url("../scss/fonts/open-sans-v35-latin/open-sans-v40-latin-600.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../scss/fonts/open-sans-v35-latin/open-sans-v40-latin-600.svg#OpenSans") format("svg"); /* Legacy iOS */
}


/* montserrat-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  src: url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-100.eot"); /* IE9 Compat Modes */
  src:
    url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-100.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-100.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-100.woff") format("woff"),
    /* Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-100.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-100.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.eot"); /* IE9 Compat Modes */
  src:
    url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-500.eot"); /* IE9 Compat Modes */
  src:
    url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-500.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-600.eot"); /* IE9 Compat Modes */
  src:
    url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-600.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-600.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-700.eot"); /* IE9 Compat Modes */
  src:
    url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../scss/fonts/montserrat-v25-latin/montserrat-v25-latin-700.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
