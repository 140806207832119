abbr {
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.react-calendar {
  position: absolute;
  top: 6px;

  background: #ffffff;

  box-shadow: 0 2px 4px rgba(203, 173, 115, 0.2), 0 3px 16px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.react-date-picker__wrapper {
  display: none;
}

.react-calendar__tile--active {
  background: #cbad73;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #b09767;
}

@media (max-width: 599px) {
  .react-calendar {
    width: 300px;
  }
}
